<template>
    <section id="workingArea">
        <div class="gdnBorder">
            <div class="pools" >
                <div id="tableCont" class="inner">
                    <div class="align-items-center">
                        <h3 style="font-size:18px;">Liquidity Pools<br><br>
                            <small>Earn yield on trading fees by providing liquidity</small>
                        </h3>
                        <!-- <div class="col-12 col-md-7 text-right">
                            <div class="time">
                                <a href="">Time Basis: 
                                    <samp>7D</samp>
                                    <i>
                                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="284.929px" height="284.929px" viewBox="0 0 284.929 284.929" style="enable-background:new 0 0 284.929 284.929;" xml:space="preserve">
                                            <g>
                                                <path d="M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441
                                                    L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082
                                                    c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647
                                                    c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z">
                                                </path>
                                            </g>
                                        </svg>
                                    </i>
                                </a>
                            </div>
                        </div> -->
                        <!-- <div class="col-12 col-md-2">
                            <form action="" class="d-flex align-items-center">
                                <i>
                                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                        width="612.08px" height="612.08px" viewBox="0 0 612.08 612.08" style="enable-background:new 0 0 612.08 612.08;"
                                        xml:space="preserve">
                                        <g>
                                            <path d="M237.927,0C106.555,0,0.035,106.52,0.035,237.893c0,131.373,106.52,237.893,237.893,237.893
                                                c50.518,0,97.368-15.757,135.879-42.597l0.028-0.028l176.432,176.433c3.274,3.274,8.48,3.358,11.839,0l47.551-47.551
                                                c3.274-3.274,3.106-8.703-0.028-11.838L433.223,373.8c26.84-38.539,42.597-85.39,42.597-135.907C475.82,106.52,369.3,0,237.927,0z
                                                M237.927,419.811c-100.475,0-181.918-81.443-181.918-181.918S137.453,55.975,237.927,55.975s181.918,81.443,181.918,181.918
                                                S338.402,419.811,237.927,419.811z"/>
                                        </g>
                                    </svg>
                                </i>
                                <input type="search" class="form-control" placeholder="Search by token"> 
                            </form>
                        </div> -->
                    </div>
                    <div>
                        <q-table
                                title=""
                                class="transparent custom-table"
                                :data="filteredPool"
                                :columns="columns"
                                row-key="id"
                                v-if="!isMobile"
                                :key="'input'+rerender"
                                :pagination.sync="pagination"
                                hide-bottom
                                binary-state-sort
                                :sort-method="mySort"
                        >
                        <template v-slot:body="props">
                            <q-tr :props="props" @click.native="isMetaMaskConnected ? props.expand = !props.expand : null" 
                                    class="cursor-pointer">
                                <q-td>
                                    <div class="star flex items-center no-wrap">
                                        <i>
                                            <svg fill="none" height="24" stroke-width="1.5" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M8.58737 8.23597L11.1849 3.00376C11.5183 2.33208 12.4817 2.33208 12.8151 3.00376L15.4126 8.23597L21.2215 9.08017C21.9668 9.18848 22.2638 10.0994 21.7243 10.6219L17.5217 14.6918L18.5135 20.4414C18.6409 21.1798 17.8614 21.7428 17.1945 21.3941L12 18.678L6.80547 21.3941C6.1386 21.7428 5.35909 21.1798 5.48645 20.4414L6.47825 14.6918L2.27575 10.6219C1.73617 10.0994 2.03322 9.18848 2.77852 9.08017L8.58737 8.23597Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                        </i>
                                        <span class="mr-2 avatar">
                                            <img :src="getLogo(0, props.row.id)" alt="">
                                        </span>
                                        <span class="usdc avatar">
                                            <img :src="getLogo(1, props.row.id)" alt="">
                                        </span>
                                        {{getPairName(props.row.id)}}
                                    </div>
                                </q-td>
                                <q-td class="text-bold text-right">
                                    ${{getTVL(props.row.id).toFormat(2)}}
                                </q-td>
                                <q-td class="text-bold text-right">
                                    ${{getVolume1H(props.row.id).toFormat(2)}}
                                </q-td>
                                <q-td class="text-bold text-right">
                                    ${{getVolume24H(props.row.id).toFormat(2)}}
                                </q-td>
                                <q-td class="text-bold text-right">
                                    {{getAPR(props.row.id).toFormat(2)}}<span class="token">%</span>
                                </q-td>
                                <q-td>
                                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="284.929px" height="284.929px" viewBox="0 0 284.929 284.929" style="enable-background:new 0 0 284.929 284.929;" xml:space="preserve">
                                        <g>
                                            <path d="M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441
                                                L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082
                                                c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647
                                                c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z"></path>
                                        </g>
                                    </svg>
                                </q-td>
                            </q-tr>
                            <q-tr v-show="props.expand" :props="props" >
                                <q-td colspan="6" style="border-radius:20px" class="subPanel">
                                    <div class="col-12 flex justify-between items-center q-pa-sm ">
                                        <div>
                                            <small>Your Liquidity</small><br />
                                            ${{getYourLPPrice(props.row.id)}}<br />
                                            {{getYourLPSupply(props.row.id)}} <span class="token">LP</span>
                                        </div>
                                        <div class="q-px-md">
                                            <small>Total Assets Pooled</small><br />
                                            {{getToken0Reserve(props.row.id)}} 
                                            <span class="token">{{getToken0Symbol(props.row.id)}}</span><br />
                                            {{getToken1Reserve(props.row.id)}} 
                                            <span class="token">{{getToken1Symbol(props.row.id)}}</span><br />
                                        </div>
                                        <div>
                                            <small>Your Share</small><br />
                                            {{getYourShare(props.row.id)}}<span class="token"> %</span>
                                        </div>
                                        <div>
                                            <router-link :to="getLiquidityURL(0, props.row.id)">
                                                <div class="btn sub">Add Liquidity</div>
                                            </router-link>
                                            <router-link to="/farms">
                                                <div class="btn sub" title="Farms" style="padding:11px 15px">
                                                    <img src="@/assets/images/farm.svg" style="width:20px">
                                                </div>
                                            </router-link>
                                            <router-link :to="getLiquidityURL(1, props.row.id)">
                                                <div class="btn" title="Remove liquidity" 
                                                    style="font-size:25px; padding:0px 25px 7px 25px">
                                                    ̶̶̶̶̶̶̶̶̶̶
                                                </div>
                                            </router-link>
                                            <router-link :to="getSwapURL(props.row.id)">
                                                <div class="btn sub" title="Swap" style="padding:11px 15px">
                                                    <img src="@/assets/images/swap.svg" style="width:20px">
                                                </div>
                                            </router-link>
                                        </div>
                                    </div>
                                </q-td>
                            </q-tr>
                        </template>
                        </q-table>
                        <q-table
                                title=""
                                :dense="$q.screen.lt.md"
                                class="transparent custom-table"
                                :data="filteredPool"
                                :columns="columnsMobile"
                                row-key="id"
                                v-if="isMobile"
                                hide-header
                                :pagination.sync="pagination"
                                hide-bottom
                                binary-state-sort
                                :sort-method="mySort"
                        >
                        <template v-slot:body="props">
                            <q-tr v-show="!props.expand" :props="props" 
                                    @click.native="isMetaMaskConnected ? props.expand = !props.expand : null" 
                                class="cursor-pointer">
                                <q-td>
                                    <div class="star flex items-center no-wrap">
                                        <span class="mr-2 avatar">
                                            <img :src="getLogo(0, props.row.id)" alt="">
                                        </span>
                                        <span class="usdc avatar">
                                            <img :src="getLogo(1, props.row.id)" alt="">
                                        </span>
                                    </div>
                                    <div style="font-size:0.5rem;">
                                        {{getPairName(props.row.id)}}
                                    </div>
                                </q-td>
                                <q-td class="text-bold" style="font-size:0.6rem;">
                                    <span style="color:#abc4ff80">Liquidity</span><br>
                                    ${{getTVL(props.row.id).toFormat(2)}}
                                </q-td>
                                <q-td class="text-bold" style="font-size:0.6rem;">
                                    <span style="color:#abc4ff80">Volume 7D</span><br>
                                    ${{getVolume24H(props.row.id).toFormat(2)}}
                                </q-td>
                            </q-tr>
                            <q-tr v-show="props.expand" :props="props" 
                                    @click.native="isMetaMaskConnected ? props.expand = !props.expand : null" >
                                <q-td>
                                    <div class="star flex items-center no-wrap">
                                        <span class="mr-2 avatar">
                                            <img :src="getLogo(0, props.row.id)" alt="">
                                        </span>
                                        <span class="usdc avatar">
                                            <img :src="getLogo(1, props.row.id)" alt="">
                                        </span>
                                    </div>
                                    <div style="font-size:0.6rem;">
                                        {{getPairName(props.row.id)}}
                                    </div>
                                    <div style="font-size:0.5rem; margin-left:10px; margin-top:10px;">
                                         <span style="color:#abc4ff80">&nbsp;</span><br>&nbsp;
                                    </div>
                                </q-td>
                                <q-td class="text-bold" style="font-size:0.6rem;">
                                    <span style="color:#abc4ff80">Liquidity</span><br>
                                    ${{getTVL(props.row.id).toFormat(2)}}
                                        <div style="margin-top:10px; padding-left:0">
                                            <span style="color:#abc4ff80">Volume 1H</span><br>
                                            ${{getVolume1H(props.row.id).toFormat(2)}}
                                        </div>
                                </q-td>
                                <q-td class="text-bold" style="font-size:0.6rem;">
                                    <span style="color:#abc4ff80">Volume 24H</span><br>
                                    ${{getVolume24H(props.row.id).toFormat(2)}}
                                    <div style="margin-top:10px;">
                                        <span style="color:#abc4ff80">APR 7D</span><br>
                                        {{getAPR(props.row.id).toFormat(2)}}%
                                    </div>
                                </q-td>
                            </q-tr>
                            <q-tr v-show="props.expand" :props="props">
                                <q-td colspan="5" style="border-radius:10px;" class="subPanel">
                                    <div class="col-12 flex justify-between items-center q-mt-sm text-bold"
                                            style="padding:0;">
                                        <div style="width:100%">
                                            <div class="flex">
                                                <div style="width:40%">
                                                    <span class="label">Your Liquidity : </span>
                                                </div>
                                                <div style="width:60%">${{getYourLPPrice(props.row.id)}}</div>
                                            </div>
                                            <div class="flex">
                                                <div style="width:40%">
                                                    <span class="label"></span>
                                                </div>
                                                <div style="width:60%">
                                                    {{getYourLPSupply(props.row.id)}} 
                                                    <span class="token">LP</span>
                                                </div>
                                            </div>
                                            <div class="flex q-mt-sm">
                                                <div style="width:40%">
                                                    <span class="label">Total Asses Pooled : </span>
                                                </div>
                                                <div style="width:60%">
                                                    {{getToken0Reserve(props.row.id)}} 
                                                    <span class="token">{{getToken0Symbol(props.row.id)}}</span>
                                                </div>
                                            </div>
                                            <div class="flex">
                                                <div style="width:40%">
                                                    <span class="label"></span>
                                                </div>
                                                <div style="width:60%">
                                                    {{getToken1Reserve(props.row.id)}} 
                                                    <span class="token">{{getToken1Symbol(props.row.id)}}</span>
                                                </div>
                                            </div>
                                            <div class="flex q-mt-sm">
                                                <div style="width:40%">
                                                    <span class="label" >Your Share : </span>
                                                </div>
                                                <div style="width:60%">{{getYourShare(props.row.id)}}
                                                    <span class="token">%</span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-h7 col-12 justify-center flex" style="padding:5px 0;">
                                        <router-link :to="getLiquidityURL(0, props.row.id)">
                                            <div class="btn sub" style="font-size:0.6rem">Add Liquidity</div>
                                        </router-link>
                                        <router-link to="/farms">
                                            <div class="btn sub" title="Farms">
                                                <img src="@/assets/images/farm.svg" style="width:15px">
                                            </div>
                                        </router-link>
                                        <router-link :to="getLiquidityURL(1, props.row.id)">
                                            <div class="btn" title="Remove liquidity" 
                                                style="margin:5px; padding:7px 16px 13px">
                                                ̶̶̶̶̶̶̶̶̶̶
                                            </div>
                                        </router-link>
                                        <router-link :to="getSwapURL(props.row.id)">
                                            <div class="btn sub" title="Swap">
                                                <img src="@/assets/images/swap.svg" style="width:15px">
                                            </div>
                                        </router-link>                                       
                                    </div>
                                </q-td>
                            </q-tr>
                        </template>
                        </q-table>
                    </div>
                </div>                
            </div>
        </div>
    </section>
</template>
<script>
    import { BigNumber } from 'bignumber.js'
    import {pool_list} from '@/constants/pool.js'
    import axios from 'axios'
    import { sortDate } from '../utils/sort.js'
    import { isNumber, isDate, isObject } from '../utils/is.js'

  export default {
    name: "Pool",
    data() {
        return {
            rerender: 0,
            filter: 'all',
            pagination: {
                rowsPerPage: 30
            },
            columns: [
                {
                    name: 'pool',
                    field: 'pool',
                    label: 'Pool',
                    align: 'center',
                    sortable: true
                },
                {
                    name: 'liquidity',
                    field: 'liquidity',
                    label: 'Liquidity',
                    align: 'right',
                    sortable: true,
                },
                {
                    name: 'volume_1h',
                    field: 'volume_1h',
                    label: 'Volume 1H',
                    align: 'right',
                    sortable: true
                },
                {
                    name: 'volume_24h',
                    field: 'volume_24h',
                    label: 'Volume 24H',
                    align: 'right',
                    sortable: true
                },
                {
                    name: 'apr',
                    field: 'apr',
                    label: 'APR',
                    align: 'right',
                    sortable: true,
                },
                {
                    name: 'arrow',
                    field: 'arrow',
                    label: '',
                    align: 'center',
                },
            ],
            columnsMobile: [
                {
                    name: 'pool',
                    field: 'pool',
                    label: 'Pool',
                    align: 'left',
                    sortable: true
                },
                {
                    name: 'liquidity',
                    field: 'liquidity',
                    label: 'Liquidity',
                    align: 'left',
                    sortable: true
                },
                {
                    name: 'volume_7D',
                    field: 'volume_7D',
                    label: 'Volume 7D',
                    align: 'left',
                    sortable: true
                },
            ],
        };
    },
    computed: {
        isMobile() {
            return this.$q.screen.width < 996;
        },
        isMetaMaskConnected() {
            return this.$store.state.account!=null;
        },
        filteredPool() {
            var rows = [];
            let length = pool_list.length;
            for (let i = 0; i < length; i++) {
                rows[i] = {
                    id : i,
                    liquidity: this.getTVL(i),
                    volume_1h: this.getVolume1H(i),
                    volume_24h: this.getVolume24H(i),
                    apr: this.getAPR(i),
                }
            }

            return rows
        },
    },
    methods: {
        forceUpdate() {
            this.rerender++;
        },
        getLogo(kind, index) {
            if (kind == 0) {
                let tokenInfo = this.$store.state.liquidity.tokenMap.get(pool_list[index].symbol0);
                return tokenInfo.logo;
            }
            else {
                let tokenInfo = this.$store.state.liquidity.tokenMap.get(pool_list[index].symbol1);
                return tokenInfo.logo;
            }
        },
        getPairName(index) {
            return pool_list[index].symbol0 + "-" + pool_list[index].symbol1
        },
        getLiquidityURL(kind, index) {
            return "/liquidity/" + kind + "/" + pool_list[index].symbol0 + "/" + pool_list[index].symbol1
        },
        getSwapURL(index) {
            return "/swap/" + pool_list[index].symbol0 + "/" + pool_list[index].symbol1
        },
        getToken0Reserve(index) {
            if(BigNumber(this.$store.state.pool.reserved0[index]).isNaN())
                return '0.00';
            let decimals = this.$store.state.pool.decimals0[index];
            return this.getDecimalData(BigNumber(this.$store.state.pool.reserved0[index])
                .shiftedBy(decimals));
        },
        getToken1Reserve(index) {
            if(BigNumber(this.$store.state.pool.reserved1[index]).isNaN())
                return '0.00';

            let decimals = this.$store.state.pool.decimals1[index];
            return this.getDecimalData(BigNumber(this.$store.state.pool.reserved1[index])
                .shiftedBy(decimals));
        },
        getToken0Symbol(index) {
            return pool_list[index].symbol0;
        },
        getToken1Symbol(index) {
            return pool_list[index].symbol1;
        },
        getYourLPSupply(index) {
            if(this.$store.state.account && this.$store.state.pool.tokenbalance[index] > 0)
                return this.getDecimalData(BigNumber(this.$store.state.pool.tokenbalance[index])
                        .shiftedBy(-18));
            else
                return '0.00'

        },
        getYourLPPrice(index) {
            if(this.$store.state.account && this.$store.state.pool.tokenbalance[index] > 0) {
                let value = BigNumber(this.$store.state.liquidity.tvl[index])
                    .times(BigNumber(this.$store.state.pool.tokenbalance[index]))
                    .div(BigNumber(this.$store.state.pool.liquiditybalance[index]));

                return this.getDecimalData(value);
            }
            else
                return '0.00'
        },
        getYourShare(index) {
            let myShare = BigNumber(this.$store.state.pool.tokenbalance[index]);
            let total = BigNumber(this.$store.state.pool.liquiditybalance[index]);

            if (myShare > 0)
                return myShare.times(100).div(total).toFormat(2);
            else
                return '0.00'
        },
        getTVL(index) {
            if (this.$store.state.liquidity.tvl[index] > 0)
                return BigNumber(this.$store.state.liquidity.tvl[index]);

            return BigNumber(0);
        },
        getVolume1H(index) {
            if (this.$store.state.liquidity.pairInfo[index].pair.volume.h1 > 0)
                return BigNumber(this.$store.state.liquidity.pairInfo[index].pair.volume.h1);

            return BigNumber(0);
        },
        getVolume24H(index) {
            if (this.$store.state.liquidity.pairInfo[index].pair.volume.h24 > 0)
                return BigNumber(this.$store.state.liquidity.pairInfo[index].pair.volume.h24);

            return BigNumber(0);
        },
        getFree(index) {
            return BigNumber(0);
        },
        getAPR(index) {
            if (this.$store.state.pool.totalSupply[index] == 0)
                return BigNumber(0);

            let poolId = pool_list[index].poolId;
            if (poolId == undefined) {
                return BigNumber(0);
            }
                
            let lpPrice = BigNumber(this.$store.state.liquidity.tvl[index])
                    .div(BigNumber(this.$store.state.pool.liquiditybalance[index]).shiftedBy(-18));

            let apr = BigNumber(10).times(1*24*3600*365/12)      // 5 tokens per 15s
                .times(this.$store.state.pool.allocpoint[index])
                .div(this.$store.state.farming.totalAllocPoint)
                .times(this.$store.state.price.STAR)
                .div(
                    BigNumber(this.$store.state.pool.totalSupply[index]).shiftedBy(-18)
                        .times(lpPrice)
                )
                .times(100);

            if (apr > 0)
                return BigNumber(apr);
            else
                return BigNumber(0);
        },
        getDecimalData(value) {
            let limit_xxx = BigNumber(1000);
            let limit_xx = BigNumber(1);
            let limit_x = BigNumber(1).shiftedBy(-3);
            let limit_sm = BigNumber(1).shiftedBy(-6);
            let limit_md = BigNumber(1).shiftedBy(-9);
            let limit_lg = BigNumber(1).shiftedBy(-12);

            if (limit_lg.isGreaterThan(value)) {
                return value.toString();
            }
            else if (limit_md.isGreaterThan(value)) {
                return value.decimalPlaces(12, 1).toString();
            }
            else if (limit_sm.isGreaterThan(value)) {
                return value.decimalPlaces(9, 1).toString();
            }
            else if (limit_x.isGreaterThan(value)) {
                return value.decimalPlaces(6, 1).toString();
            }
            else if (limit_xx.isGreaterThan(value)) {
                return value.decimalPlaces(4, 1).toString();
            }
            else if (limit_xxx.isGreaterThan(value)) {
                return value.decimalPlaces(2, 1).toString();
            }
            else {
                return value.decimalPlaces(0, 1).toString();
            }
        },
        mySort (data, sortBy, descending) {
            const col = this.columns.find(def => def.name === sortBy)
            if (col === null || col.field === void 0) {
                return data
            }

            const
                dir = descending ? -1 : 1,
                val = typeof col.field === 'function'
                ? v => col.field(v)
                : v => v[col.field]

            return data.sort((a, b) => {
                let
                    A = val(a),
                    B = val(b)

                if (A === null || A === void 0) {
                    return -1 * dir
                }
                if (B === null || B === void 0) {
                    return 1 * dir
                }
                if (col.sort) {
                    return col.sort(A, B) * dir
                }

                if (!isNaN(A) && !isNaN(B)) {
                    return (A - B) * dir
                }
                if (isDate(A) && isDate(B)) {
                    return sortDate(A, B) * dir
                }
                if (typeof A === 'boolean' && typeof B === 'boolean') {
                    return (a - b) * dir
                }

                [A, B] = [A, B].map(s => (s + '').toLowerCase())

                return A < B
                    ? -1 * dir
                    : (A === B ? 0 : dir)
            })
        }
    }
  }
</script>

<style scoped>

</style>
